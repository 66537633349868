/*Internet Explorer*/


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .nav-cards {
      display: block;
        &:before {
            content: "";
            clear: both;
        }

        .col {
            float: left;
            width: auto;
        }
    }

    body{
        font-family: "Montserrat-Medium";
    }
}

body{
    font-family: "Montserrat-Medium";
}

#master-nav>li.active>.nav-link, #master-nav>li:hover>.nav-link {    
    font-weight: 100 !important;    
  }


section:after, .navbar-nav:after, footer .row:after, footer .bg-silver-darkest:after, .footer .col-md-12 div:after, .nav-cards.tertiary-pages:after {
    display: block;
    content: "";
    clear: both;
}

.features-services {
    .details {
        margin-left: 4em;
        width: 80%;
    }
}

.features-services svg, .tab-pane {
    float: left;
}

.contact-header {
    float: right;
}

.nav-cards {
    .card {
        .header {
            svg {
                margin: 0 auto;
                margin-bottom: 1em;
            }
        }
    }
}


.nav-cards {
    &.tertiary-page {
        .arrow {
            float: right;
        }

        p {
            text-align: left;
        }

        .header {
            p {
                float: left;
                width: 80%;
            }
        }
    }
}

.features-document {
    .icon {
        display: none;
    }
}

.pricing-cards {
    .choose {
        position: relative;
    }
}

.cta-section {
    .btn {
        margin-top: -20px;
    }
}
